module.exports = [
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00901-m/0001",
      dbname: "APB_9F_IAQ_0001",
      floor: "9",
      type: "female",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00901-f/0001",
      dbname: "APB_9F_IAQ_0002",
      floor: "9",
      type: "male",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00901-u/0001",
      dbname: "APB_9F_IAQ_0003",
      floor: "9",
      type: "zaccessible",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00801-m/0001",
      dbname: "APB_8F_IAQ_0001",
      floor: "8",
      type: "female",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00801-f/0001",
      dbname: "APB_8F_IAQ_0002",
      floor: "8",
      type: "male",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00801-u/0001",
      dbname: "APB_8F_IAQ_0003",
      floor: "8",
      type: "zaccessible",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00g01-m/0001",
      dbname: "APB_GF_IAQ_0001",
      floor: "G",
      type: "male",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00g01-f/0001",
      dbname: "APB_GF_IAQ_0002",
      floor: "G",
      type: "female",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00g01-u/0001",
      dbname: "APB_GF_IAQ_0003",
      floor: "G",
      type: "zaccessible",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00601-m/0001",
      dbname: "APB_6F_IAQ_0001",
      floor: "6",
      type: "male",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00601-f/0001",
      dbname: "APB_6F_IAQ_0002",
      floor: "6",
      type: "female",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00601-u/0001",
      dbname: "APB_6F_IAQ_0003",
      floor: "6",
      type: "zaccessible",
      cubicleId: "1",
      block: "main"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00802-m/0001",
      dbname: "APB_BOLD_8F_IAQ_0001",
      floor: "8",
      type: "male",
      cubicleId: "1",
      block: "bold"
    },
    {
      mqtt: "v1/shio/shioiaq2/d-kc/apb00802-f/0001",
      dbname: "APB_BOLD_8F_IAQ_0002",
      floor: "8",
      type: "female",
      cubicleId: "1",
      block: "bold"
    },
  ];
  